import { SelectionControl, SelectedColumn } from 'o365.modules.BaseSelectionControl.ts';
import type { DataItemModel, ItemModel } from 'o365.modules.DataObject.Types.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts';

export default class DataSelectionControl<T extends ItemModel = ItemModel> extends SelectionControl<T> {
    /** Main */
    private _data: Partial<T>[];
    private _getData: () => Partial<T>[];
    private _selectedDataItems: Set<Partial<DataItemModel>> = new Set();

    selectAllLoading: boolean = false;

    get selectedDataItems() {
        return this._selectedDataItems;
    }

    get selectedRows() {
        return Array.from(this._selectedDataItems) as T[];
    }

    constructor(pOptions: { data: Partial<T>[], getData?: () => Partial<T>[] }) {
        super();
        this._data = pOptions.data;
        this._getData = pOptions.getData ?? (() => this._data);
        this.checkIsSelected();
        this.allRowsSelected = this.isAllRowsSelected();
    }

    checkIsSelected() {
        this._getData()?.forEach(item => {
            if (item.isSelected) {
                this._selectedDataItems.add(item);
            }
        })
    }

    onSelection(pRow: T) {
        if (pRow.item.isSelected) {
            this._selectedDataItems.add(pRow.item);
        } else {
            this._selectedDataItems.delete(pRow.item);
        }

        this.allRowsSelected = this.isAllRowsSelected();
    }

    isAllRowsSelected() {
        return this._selectedDataItems.size === this._getData()?.length;
    }

    isSomeRowsSelected() {
        if (this._selectedDataItems.size === 0) { return false; }
        if (this._selectedDataItems.size < this._getData()?.length) { return true; }
        return false;
    }

    getSelection(columnArray: DataColumn[], rowData?: T[], pOptions?: {
        valueResolve?: (pColumn: any, pRow: T) => [string, any],
    }) {
        if (this.isValidSelection) {
            const srcRows = rowData;
            if (!srcRows) { return []; }
            const rows = this.getRows(srcRows);
            const columns = this.getColumns(columnArray);
            if (rows == null || columns == null) { return; }
            const selected: Record<string, any>[] = [];
            rows.forEach((row, index) => {
                selected[index] = {};
                selected[index].index = row.index;
                columns.forEach(col => {
                    if (pOptions?.valueResolve) {
                        const resolvedValues = pOptions.valueResolve(col, row);
                        if (Array.isArray(resolvedValues)) {
                            const [fieldName, fieldValue] = resolvedValues;
                            selected[index][fieldName] = fieldValue;
                        } else if (typeof resolvedValues == 'object') {
                            Object.entries(resolvedValues).forEach(([fieldName, fieldValue]) => {
                                if (fieldName == '$filter' && typeof selected[index].$filter == 'object' && typeof fieldValue == 'object') {
                                    selected[index][fieldName] = { ...selected[index][fieldName], ...fieldValue };
                                } else {
                                    selected[index][fieldName] = fieldValue;
                                }
                            });
                        }
                    } else {
                        selected[index][col.name] = row[col.name];
                    }
                });
            });
            return selected;
        } else {
            return [];
        }
    }

    getSelectedRowsData(columnArray: SelectedColumn[]) {
        const selectedRows = this._getData()?.filter((item) => item.isSelected);
        const selection: Record<string, any>[] = [];
        if (selectedRows) {
            selectedRows.forEach((row) => {
                let createdRow: Record<string, any> = {}
                columnArray.forEach((item) => {
                    const fieldName = typeof item === 'string' ? item : item.name;
                    if (!fieldName.startsWith('o365')) {
                        createdRow[fieldName] = row[fieldName];
                    }
                });
                selection.push(createdRow);
            });
        }
        return selection;
    }

    selectAll(setSelectionTo: boolean = true) {
        this.allRowsSelected = !this.allRowsSelected;
        this._getData()?.forEach(item => {
            item.isSelected = setSelectionTo;
            this._selectedDataItems.add(item);
        })

        if (!setSelectionTo) {
            this._selectedDataItems.clear();
        }
    }

    copySelection(withHeaders: boolean = false, gridColumns: SelectedColumn[], copyAsJson = false) {
        let selectedRows: T[] | null = null;
        if (this._selectedDataItems.size > 0) {
            selectedRows = Array.from(this._selectedDataItems) as T[];
        } else {
            selectedRows = this._getData()?.filter(item => item.isSelected) as T[];
        }
        if (selectedRows == null) {
            return;
        }
        const columns = this.getColumnsForCopy(selectedRows, gridColumns);
        const rows = selectedRows.length ? selectedRows : this.getRows(this._getData());
        if (columns == null || rows == null) { return; }
        let result = '';
        const jsonCopy: Record<string, any>[] = [];
        if (columns && columns.length > 0 && withHeaders) {
            columns.forEach((col, colIndex) => {
                result += col.caption;
                if (colIndex < columns.length - 1) { result += '\t'; }
            })
            result += '\n';
        }
        rows.forEach((row, rowIndex) => {
            let jsonColumnData: Record<string, any> = {};
            columns.forEach((col, colIndex) => {
                if (col.unbound) {
                    if (col.getCopyValue) {
                        result += col.getCopyValue(row, 0) ?? '\t';
                        jsonColumnData[col.name] = col.getCopyValue(row, 0);
                    }
                } else {
                    const value = row[col.name];
                    if (value) {
                        let formatedValue = this.formatForCopyValue(value, col.type);;
                        jsonColumnData[col.name] = formatedValue;
                        result += formatedValue;
                    }
                }
                if (colIndex < columns.length - 1) { result += '\t'; }
            });

            jsonCopy.push(jsonColumnData);
            if (rowIndex < rows.length - 1) { result += '\n'; }
        });
        result = copyAsJson ? JSON.stringify(jsonCopy) : result;
        navigator.clipboard.writeText(result)
            .then(() => console.log("Success copy", result))
            .catch((error) => console.error("error", error));
    }

    handleJsonPaste(text: string, columns: DataColumn[]) {
        return false;
    }

    pasteSelection(event: KeyboardEvent, columns: DataColumn[]) {
        return;
    }

    selectRange(pValue: boolean, pStart: number, pEnd: number) {
        if (pStart > pEnd) { throw new TypeError('End cannot be greater than start in selection range'); }
        this._getData()?.slice(pStart, pEnd + 1).forEach(item => {
            item.isSelected = pValue;
        });

        this.allRowsSelected = this.isAllRowsSelected();
    }
}